<template>
<div>
  <b-container fluid>
    <b-row class="my-0" v-if="permissionDeleteKeyword">
      <b-col class="d-flex justify-content-end">
        <div>
          <b-button v-b-toggle.collapse-1 variant="primary">Delete keyword</b-button>
          <b-collapse id="collapse-1" class="mt-2">
            <b-card>
              <p class="card-text">Warning - hard delete, not possible to reverse</p>
              <b-button @click="deleteKeyword">DELETE</b-button>
            </b-card>
          </b-collapse>
        </div>
      </b-col>
    </b-row>
    <b-row class="my-5">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Keyword: {{ keyword }}</h1>
        <p>Explore trends and emerging topics based on AI generated keywords.
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <strong>monthly keyword frequency</strong>
        <div id="chart" style="width: 100%; height: 250px;"></div>
      </b-col>
    </b-row>
    <b-row v-if="!loading">
      <b-col>
        <b-card class="mb-3" v-for="item in news" :key="item.id" :id="item.id">
          <publication
            v-bind:propItem="item"
            v-bind:key="item.id"
            :tagsProp=tags
            v-bind:username="user.username"
            :permissionAddArticle="permissionAddArticle"
            :permissionAddRisk="permissionAddRisk"
            :permissionAddTag="permissionAddTag"
            :permissionArticlesWip="permissionArticlesWip"
            :permissionDeletePublication="permissionDeletePublication"
            :permissionInsights="permissionInsights"
            :permissionReadTagsPanel="permissionReadTagsPanel"
            />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import ac from '@/libs/accesscontrol'
import moment from 'moment'

import Publication from '@/components/PublicationNew'

import * as echarts from 'echarts'
const theme = { color: ['#00338D', '#005EB8', '#0091DA', '#483698', '#470A68', '#6D2077', '#00A3A1', '#009A44', '#43B02A', '#EAAA00'] }
echarts.registerTheme('kpmg', theme)
export default {
  name: 'KeywordsHome',
  components: {
    Publication
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
      endDate: moment().format('YYYY-MM-DD'),
      interval: 'month',
      keyword: '',
      loading: true,
      limit: 20,
      news: [],
      offset: 0,
      permissionAddArticle: false,
      permissionAddRisk: false,
      permissionAddTag: false,
      permissionArticlesWip: false,
      permissionDeletePublication: false,
      permissionDeleteKeyword: false,
      permissionReadAnalytics: false,
      permissionInsights: false,
      permissionReadTagsPanel: false,
      permissionEditTags: false,
      startDate: moment().subtract(365, 'days').format('YYYY-MM-DD'),
      tags: []
    }
  },
  created: async function () {
    this.tags = await this.$Amplify.API.get('cosmos', '/tags')
    this.load()
    this.permissionAddArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permissionAddRisk = true
    this.permissionAddTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permissionArticlesWip = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.permissionDeletePublication = ac.can(this.user.acgroups).deleteAny('publication').granted
    this.permissionDeleteKeyword = ac.can(this.user.acgroups).deleteAny('keyword').granted
    this.permissionReadAnalytics = ac.can(this.user.acgroups).readAny('analytics').granted
    this.permissionInsights = true
    this.permissionReadTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    this.permissionEditTags = ac.can(this.user.acgroups).updateAny('tag').granted
    if (this.$config.BUILD === 'external') {
      this.permissionAddRisk = false
      this.permissionAddTag = false
      this.permissionArticlesWip = false
      this.permissionDeletePublication = false
      this.permissionInsights = ac.can(this.user.acgroups).readAny('article').granted
      this.permissionReadAnalytics = false
      this.permissionReadTagsPanel = false
      this.permissionEditTags = false
      this.permissionDeletePublication = false
      this.permissionDeleteKeyword = false
    }
  },
  methods: {
    deleteKeyword: async function () {
      await this.$Amplify.API.del('cosmos', `/keyword`, { body: { keyword: this.keyword } })
      this.$router.push({ name: 'Keywords' })
    },
    load: async function () {
      this.loading = true
      this.keyword = decodeURIComponent(this.$route.params.keyword)
      const graphData = await this.$Amplify.API.get('cosmos', `/stats/keyword/${this.$route.params.keyword}/${this.startDate}/${this.endDate}/${this.interval}`)
      const optionTrend = {
        toolbox: {
          show: true,
          feature: {
            dataView: { readOnly: false },
            saveAsImage: {}
          }
        },
        dataset: {
          source: graphData
        },
        xAxis: {
          type: 'time'
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: 'timeseries',
            type: 'line',
            smooth: true,
            encode: {
              y: 'count',
              x: 'date'
            }
          }
        ]
      }
      echarts.init(document.getElementById('chart'), 'kpmg').setOption(optionTrend)
      this.news = await this.$Amplify.API.get('cosmos', `/news/keyword/${this.$route.params.keyword}/limit/${this.limit}/offset/${this.offset}`)
      this.loading = false
    }
  },
  watch: {
    $route: function () {
      this.load()
    }
  }
}
</script>

<style lang="scss" scoped>
.link-card {
  font-size: 16px;
  font-weight: bold;
}
</style>
